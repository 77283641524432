import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml, Layout } from 'gatsby-theme-husky/src/layout';
import Banner from 'gatsby-theme-husky/src/components/Banner';

import './QualityPageMain.scss';
import { QualityPageComponentProps } from './models';

const QualityPage: FC<QualityPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoQuality: {
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      banner,
      text,
      url,
    },
  },
}) => (
  <Layout
    {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="quality-page"
  >
    {banner.map(({ properties: { title, ...restBannerProperties } }) => (
      <Banner
        url={url}
        key={title}
        title={title}
        {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
      />
    ))}
    <div className="quality-page__container">
      <DangerouslySetInnerHtml className="quality-page__text" html={text} />
    </div>
  </Layout>
);

export const query = graphql`
  query QualityPage($url: String = "", $lang: String) {
    umbracoQuality: umbracoQuality(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaTitle
      seoCanonicalUrl
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      text
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default QualityPage;
